<template>
    <div>
        <div class="tw-mt-10">
            <fieldset>
                <legend class="text-blue">
                    {{ $t('shiftManagement.shiftWorkingHour') }}
                    <Tooltip placement="top">
                        <Icon
                            type="ios-information-circle-outline"
                            size="17"
                        />
                        <div slot="content">
                            <div class="tw-text-xs">Shift Working Hour</div>
                        </div>
                    </Tooltip>
                </legend>
                <div
                    class="tw-mt-2 overflow-auto tw-max-h-80 tw-h-96"
                    style="min-height: 100px"
                >
                    <div class="table-ui-detail">
                        <table>
                            <thead>
                                <tr>
                                    <th width="10%">
                                        {{ $t('shiftManagement.keyword') }}
                                    </th>
                                    <th width="13%">
                                        {{ $t('shiftManagement.fromDay') }}
                                    </th>
                                    <th width="13%">
                                        {{ $t('shiftManagement.toDay') }}
                                    </th>
                                    <th width="12%">
                                        {{ $t('shiftManagement.firstIn') }}
                                    </th>
                                    <th width="12%">
                                        {{ $t('shiftManagement.firstOut') }}
                                    </th>
                                    <th width="12%">
                                        {{ $t('shiftManagement.secondIn') }}
                                    </th>
                                    <th width="12%">
                                        {{ $t('shiftManagement.secondOut') }}
                                    </th>
                                    <th width="12%">
                                        {{ $t('shiftManagement.totalHous') }}
                                    </th>
                                    <th
                                        style="text-align: center; width: 1px"
                                        width="10%"
                                    >
                                        <Tooltip
                                            placement="top"
                                            :transfer="true"
                                            :content="$t('add')"
                                        >
                                            <a
                                                href="#"
                                                class="text-primary"
                                                @click.prevent="onAddItem()"
                                            >
                                                <i class="fas fa-plus"></i> </a
                                        ></Tooltip>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    v-for="(detail, index) in items"
                                    :key="index"
                                >
                                    <td>
                                        <input
                                            type="text"
                                            :readonly="!requiredKeyword"
                                            v-model="detail.keyword"
                                            class="form-control"
                                        />
                                    </td>
                                    <td>
                                        <select
                                            v-model="detail.day_id_from"
                                            class="form-select"
                                            :disabled="!requiredWeekDay"
                                        >
                                            <option
                                                v-for="(
                                                    weekDay, index
                                                ) in weekDays"
                                                :key="index"
                                                :value="weekDay.value"
                                            >
                                                {{ weekDay.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <select
                                            v-model="detail.day_id_to"
                                            class="form-select"
                                            :disabled="!requiredWeekDay"
                                        >
                                            <option
                                                v-for="(
                                                    weekDay, index
                                                ) in weekDays"
                                                :key="index"
                                                :value="weekDay.value"
                                            >
                                                {{ weekDay.text }}
                                            </option>
                                        </select>
                                    </td>
                                    <td>
                                        <input
                                            type="time"
                                            v-model="detail.first_in"
                                            class="form-control"
                                            value="{start.format('HH:mm')}"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'details.' +
                                                        index +
                                                        '.first_in'
                                                )
                                            }"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="time"
                                            v-model="detail.first_out"
                                            class="form-control"
                                            value="{start.format('HH:mm')}"
                                            :class="{
                                                'is-invalid': errors.has(
                                                    'details.' +
                                                        index +
                                                        '.first_out'
                                                )
                                            }"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="time"
                                            v-model="detail.second_in"
                                            class="form-control"
                                            value="{start.format('HH:mm')}"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="time"
                                            v-model="detail.second_out"
                                            class="form-control"
                                            value="{start.format('HH:mm')}"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            type="text"
                                            readonly
                                            :value="
                                                calculateTime(
                                                    detail.first_in,
                                                    detail.first_out,
                                                    detail.second_in,
                                                    detail.second_out
                                                ).description
                                            "
                                            class="form-control"
                                        />
                                        <input
                                            type="text"
                                            hidden
                                            :v-model="
                                                (detail.total_hour =
                                                    calculateTime(
                                                        detail.first_in,
                                                        detail.first_out,
                                                        detail.second_in,
                                                        detail.second_out
                                                    ).value)
                                            "
                                        />
                                    </td>
                                    <td class="text-center">
                                        <Tooltip
                                            placement="top"
                                            :transfer="true"
                                            :content="$t('remove')"
                                        >
                                            <a
                                                href="#"
                                                class="text-danger"
                                                @click.prevent="
                                                    removeRow(detail)
                                                "
                                            >
                                                <Icon
                                                    type="ios-trash"
                                                    size="20"
                                                />
                                            </a>
                                        </Tooltip>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </fieldset>
            <div class="validate text-danger" v-if="errors.has('details')">
                {{ errors.first('details') }}
            </div>
        </div>
    </div>
</template>

<script>
import { Errors } from 'form-backend-validation'
import { isEmpty } from 'lodash'
import { mapGetters, mapState } from 'vuex'

export default {
    name: 'shiftWorkingHour',
    props: ['value', 'validate'],
    i18n: {
        messages: {
            en: {
                dayOfWeek: {
                    Monday: 'Monday',
                    Tuesday: 'Tuesday',
                    Wednesday: 'Wednesday',
                    Thursday: 'Thursday',
                    Friday: 'Friday',
                    Saturday: 'Saturday',
                    Sunday: 'Sunday'
                }
            },
            kh: {
                dayOfWeek: {
                    Monday: 'ច័ន្ទ',
                    Tuesday: 'អង្គារ',
                    Wednesday: 'ពុធ',
                    Thursday: 'ព្រហស្បតិ៌',
                    Friday: 'សុក្រ',
                    Saturday: 'សៅរ៌',
                    Sunday: 'អាទិត្យ'
                }
            }
        }
    },
    data () {
        return {
            errors: new Errors(),
            shiftManagementLoading: false,
            total: null,
            model: {}
        }
    },
    computed: {
        ...mapState('humanResource/shiftManagement', ['edit_data']),
        ...mapGetters('humanResource/shiftManagement', ['calculateTime']),
        isEdit () {
            return !isEmpty(this.edit_data)
        },
        items () {
            return this.model.details
        },
        weekDays () {
            return [
                { text: this.$t('dayOfWeek.Monday'), value: 1 },
                { text: this.$t('dayOfWeek.Tuesday'), value: 2 },
                { text: this.$t('dayOfWeek.Wednesday'), value: 3 },
                { text: this.$t('dayOfWeek.Thursday'), value: 4 },
                { text: this.$t('dayOfWeek.Friday'), value: 5 },
                { text: this.$t('dayOfWeek.Saturday'), value: 6 },
                { text: this.$t('dayOfWeek.Sunday'), value: 7 }
            ]
        },
        requiredKeyword () {
            if (
                this.model.shift_type === 'Normal' ||
                this.model.shift_type === 'Shift'
            ) {
                this.model.details.map(d => (d.keyword = ''))
                return false
            } else {
                return true
            }
        },
        requiredWeekDay () {
            if (
                this.model.shift_type === 'Rotation' ||
                this.model.shift_type === 'Shift'
            ) {
                this.model.details.map(d => (d.day_id_from = d.day_id_to = ''))
                return false
            } else {
                return true
            }
        }
    },
    created () {
        this.model = this.value
    },
    methods: {
        onAddItem () {
            this.model.details.push({
                record_id: 0,
                keyword: null,
                day_id_from: null,
                day_id_to: null,
                first_in: null,
                first_out: null,
                second_in: null,
                second_out: null,
                total_hour: null
            })
        },
        removeRow (item) {
            this.model.details.splice(this.model.details.indexOf(item), 1)
        }
    },
    watch: {
        validate: function (error) {
            this.errors = new Errors(error.errors)
        }
    }
}
</script>
